import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Modal, Space, Spin, Table, Tag, Form, InputRef, Select, Radio, Input, Tooltip} from "antd";
import {ExclamationCircleFilled, InfoCircleOutlined} from "@ant-design/icons";
import {fmcsa} from "../../../../../API/TT_ELD/fmcsa";
import FmcsaAllInfo from "./FMCSA_AllInfo";
import RefreshContext from "../../../../utils/context";
import {sendEvent} from "../../../../../Utils/analytics";
import moment from "moment";
import FMCSA_Audit from "./FMCSA_Audit";
import {FormInstance} from "antd/es/form";
import {useQueryClient} from "react-query";
import {calcOrder} from "../../../../components/core/calcOrder";

const {confirm} = Modal;
type numStr = string | number

interface approvalSource {
    no: numStr,
    id: numStr
    img: { whichservice: string };
    driver: { first_name: number, second_name: numStr, id: numStr }
    company: { name: numStr, id: numStr }
    name: string
    origin: string
    type: string
    link: string
    filecode: string
    start_date: { startDate: numStr, endDate: numStr }
    end_date: numStr,
    createdAt: numStr,
    status: numStr,
    processedTime: numStr,
    submissionId: numStr,
    companyId: numStr,
    driverId: numStr,
    report_status: numStr,
    action: { id: numStr, whichservice: string };
    key: React.Key;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);
const {Option} = Select;

const EditableRow: React.FC<any> = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const roleList = [
    {
        label: "Needs review",
        value: 'needs_review'
    },
    {
        label: "Checking",
        value: "checking",
    },

]
const EditableCell: React.FC<any> = ({
                                         title,
                                         editable,
                                         children,
                                         dataIndex,
                                         record,
                                         handleSave,
                                         refetch,
                                         ...restProps
                                     }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = useContext(EditableContext)!;
    const [getStatus, setGetStatus] = useState('');

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({[dataIndex]: record[dataIndex]});
    };

    const toggleCancel = () => {
        setEditing(false)
        setGetStatus('')
    }
    const queryClient: any = useQueryClient();

    const save = async () => {
        try {
            const values = await form.validateFields();
            await fmcsa.fmcsaChech(record.key, values)
            toggleEdit();
            refetch();
            // queryClient.refetchQueries(useFMCSA)
        } catch (errInfo) {
        }
    };
    let childNode = children;


    if (editable) {
        childNode = editing ?
            (
                <Space>
                    <Form.Item
                        style={{margin: 0}}
                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ]}
                    >
                        <Select ref={inputRef} onChange={(value: any, option) => {
                            setGetStatus(value)
                        }} style={{width: 150}}>
                            {roleList.map((u: any, index: any) => <Option key={index}
                                                                          value={u.value}>{u.label}</Option>)}
                        </Select>
                    </Form.Item>
                    <Button onClick={toggleCancel}>Cancel</Button>
                    <Button disabled={!getStatus} onClick={save}>Accept</Button>
                </Space>
            ) : (
                <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                    {children}
                </div>
            );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const FmcsaTable = ({data = [], onChange, total = 0, isLoading, isFetching, refetch, status, skip}: {
    data: Array<any>, onChange(current: any): void, total: number | undefined, isLoading: any, isFetching: any, refetch(): void, status: string, skip:number
}) => {

    const CustomTooltipContent = ({html}: { html: any }) => (
        <div dangerouslySetInnerHTML={{__html: html}}/>
    );
    const [getAudit, seGetAudit] = useState<any>();
    const {refetchBtn, setRefetchBtn} = useContext(RefreshContext);

    const RefToggler = () => {
        setRefetchBtn(refetchBtn + 1);
        refetch()
    };

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [getOneData, setGetOneData] = useState<approvalSource>();

    const handleCancel = (record: any) => {
        setGetOneData(record)
    }
    const fmcsaCount = localStorage.getItem('fmcsa_count');

    const column: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            sorter: {
                compare: (a: any, b: any) => a.no - b.no,
                multiple: 1,
            },
        },
        {
            title: 'Driver',
            dataIndex: 'driver',
            key: 'driver',
            render: ({first_name, second_name, id}: { first_name: numStr, second_name: numStr, id: numStr }) => {
                return (
                    <div key={id} style={{cursor: 'pointer'}}>{first_name + ' ' + second_name}</div>
                )
            }
        },
        {
            title: 'Comment',
            dataIndex: 'filecode',
            key: 'filecode',
        }, {
            title: 'Start date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: ({startDate, endDate}: { startDate: string, endDate: string }) => {
                const start_date = moment(startDate);
                const end_date = moment(endDate);
                return (
                    <div>
                        {start_date.isAfter(end_date) ? (
                            <Tag color="red">{moment(startDate).format("MM-DD-YYYY")}</Tag>
                        ) : (
                            <>
                                {moment(startDate).format("MM-DD-YYYY")}
                            </>
                        )}
                    </div>
                )
            }
        },
        {
            title: 'End date',
            dataIndex: 'end_date',
            key: 'end_date',

        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',

        },
        {
            title: () => (
                <span >
                      Type
                    <Tooltip placement="rightTop" title={<CustomTooltipContent html='<div>OUTPUT:Checking last 8 days.</div><div>OUTPUTFILE:Checking by date range.</div>' />}>
                <InfoCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
            </Tooltip>
        </span>
            ),
            dataIndex: 'type',
            key: 'type',
            render: (tag: string) => (
                <Tag color={tag === 'outputfile' ? 'green' : 'geekblue'}>
                    {tag.toUpperCase()}
                </Tag>
            ),
            filters: [
                {
                    text: 'Outputfile',
                    value: 'outputfile',
                },
                {
                    text: 'Output',
                    value: 'output',
                },
            ],
            onFilter: (value: any, record: any) => {
                return record.type === value
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Report status',
            dataIndex: 'report_status',
            key: 'report_status',
            editable: status === 'needs_review',
            render: (tag: string | null) => {
                const effectiveTag = tag ?? 'needs_review';
                return (
                    <Tag color={effectiveTag === 'needs_review' ? 'green' : 'geekblue'}>
                        {effectiveTag === 'needs_review' ? 'NEEDS REVIEW' : (status === 'checking' ? 'CHECKED' :'CHECKING')}
                    </Tag>
                );
            },
            filters: [
                {
                    text: 'Needs review',
                    value: 'needs_review',
                },
                {
                    text: 'Checking',
                    value: 'checking',
                },
            ],
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            width: 200,
            fixed: 'right',
            render: ({id, whichservice}: { id: string | number, whichservice: string }) => {
                const showConfirm = async (status: 'accepted' | 'rejected') => {
                    confirm({
                        title: 'FMCSA Report',
                        icon: <ExclamationCircleFilled rev={undefined}/>,
                        content: `Are you sure you want to ${status === 'accepted' ? 'approve' : 'reject'} this FMCSA report?`,
                        okText: `${status === 'accepted' ? 'OK' : 'Yes, Reject.'}`,
                        okButtonProps: {
                            type: 'primary',
                            danger: status === 'rejected',
                        },
                        onOk: async () => {
                            try {
                                await new Promise(resolve => setTimeout(resolve, 1000));
                                localStorage.setItem('fmcsa_count', `${Number(fmcsaCount) - 1}`);
                                const patchData = {"status": status};
                                await fmcsa.fmcsaPatch(id, patchData);
                                sendEvent('Approval', `User ${status === 'accepted' ? 'approval' : 'rejected'}`, '', 1);
                                refetch();
                                RefToggler();
                            } catch (error) {
                                refetch();
                            }
                        },
                        onCancel() {
                        },
                    });
                };
                const checkFMCSA = async () => {
                    try {
                        const response = await fmcsa.checkFmcsaPost(id);
                        seGetAudit({response, id});
                        setIsModalOpen(true)
                    } catch (errors) {

                    }
                }
                return (
                    <Space>
                        <>
                            <Button onClick={checkFMCSA}>Check</Button>
                            <Button onClick={() => setIsModalVisible(true)}>View</Button>
                            {status === 'checking' && <Button type="primary" onClick={() => showConfirm('accepted')}>
                                Accept
                            </Button>}
                            <Button type="primary" danger onClick={() => showConfirm('rejected')}>
                                Reject
                            </Button>
                        </>
                    </Space>
                )
            }

        },
    ]
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = column.map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                refetch,
            }),
        };
    });

    return (
        <div>
            <Spin size='large' spinning={isLoading || isFetching}>
                <FmcsaAllInfo data={getOneData} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}/>
                {isModalOpen &&
                    <FMCSA_Audit data={getAudit} isModalVisible={isModalOpen} setIsModalVisible={setIsModalOpen}
                                 status={status} refetch={RefToggler}/>}
                <Table style={{margin: '15px 0 0 0'}} onRow={(record: any) => {
                    return {
                        onClick: () => {
                            handleCancel(record)
                        },
                    };
                }} onChange={onChange} dataSource={data?.map((u: any, i: number): approvalSource => {
                    const obj: approvalSource = {
                        no: calcOrder(skip, 10, i),
                        img: {whichservice: u?.company?.whichservice},
                        id: u.id,
                        driver: {
                            first_name: u.driver?.first_name,
                            second_name: u.driver?.second_name,
                            id: u.driver?.id
                        },
                        company: {name: u.company.name, id: u.company?.id},
                        name: u.name,
                        origin: u.origin,
                        type: u.type,
                        link: u.link,
                        filecode: u?.filecode,
                        start_date: {startDate: u.start_date, endDate: u.end_date},
                        end_date: moment(u?.end_date,).format("MM-DD-YYYY"),
                        createdAt: moment(u?.createdAt,).format("MM-DD-YYYY"),
                        driverId: u.driverId,
                        report_status: u.report_status,
                        status: u.status,
                        processedTime: u.processedTime,
                        submissionId: u.submissionId,
                        companyId: u.companyId,
                        action: {id: u.id, whichservice: u?.company?.whichservice},
                        key: u.id
                    }
                    return obj
                })} components={components}
                       rowClassName={() => 'editable-row'}
                       columns={columns}
                       pagination={{total: total, showSizeChanger: false,}}
                />
            </Spin>
        </div>
    );
};

export default FmcsaTable;